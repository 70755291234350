import { useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect } from 'react';

const Redirection = ({
  pageContext: { destination },
}: {
  pageContext: {
    destination: string;
  };
}) => {
  const { navigate } = useI18next();

  useEffect(() => {
    navigate(destination, { replace: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Redirection;
